import '../styles/app.css';
import pls_icon from '../images/pls_icon.png';
import { Telephone, EnvelopeAt, Facebook, Google } from 'react-bootstrap-icons';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';



function TopBar({
  home,
  setHome,
  mission,
  setMission,
  services,
  setServices,
  about,
  setAbout,

}) {
  return (<div className="TopBar">
    <Navbar expand="lg" sticky='top' >
        <Container>
          <Navbar.Brand>
            <div onClick={ () => { 
            setHome(true) 
            setMission(false)
            setServices(false)
            setAbout(false)

          }}><img
              src={pls_icon}
              width="60"
              height="60"
              className="d-inline-block align-middle"
              alt="Plumbline Striping"
            />{'  '}Plumbline Striping</div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">

          <Nav>
          <Nav.Link onClick={ () => { 
            setHome(true) 
            setMission(false)
            setServices(false)
            setAbout(false)

          }}>Home</Nav.Link>
          </Nav>
          <Nav>
          <Nav.Link onClick={ () => {
            setHome(false) 
            setMission(false)
            setServices(true)
            setAbout(false)

          }}>Services</Nav.Link>
          </Nav>
          <Nav>
          <Nav.Link onClick={ () => {
            setHome(false) 
            setMission(true)
            setServices(false)
            setAbout(false)

            
          }}>Mission</Nav.Link>
          </Nav>

          <Nav>
          <Nav.Link onClick={ () => {
            setHome(false) 
            setMission(false)
            setServices(false)
            setAbout(true)

          }}>About Us</Nav.Link>
          
          </Nav>

          
          <NavDropdown title="Contact" id="basic-nav-dropdown">

              <NavDropdown.Item href="#action/3.1">
              <EnvelopeAt size={20} /> <a href="mailto:plumblinestriping@gmail.com?subject=Service Inquiry"> EMail</a>
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
              <Telephone size={20} /> <a href="tel:1-608-448-6373"> Call/Text: 1-608-448-6373</a>
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
              <Facebook size={20} /> <a href="https://www.fb.com/plumblinestriping" target="_blank" rel="noreferrer">Facebook</a>
              </NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
            </NavDropdown>

        </Navbar.Collapse>
      </Container>
    </Navbar>
    

  </div>);
}

export default TopBar;


// <div className="topBar">


// <div className="topBarContainer">
//   <div className="logoImg"><img src={pls_icon} alt="Plumbline Striping Logo" /></div>

//     <div className="companyTitle">
//       <h2>Plumbline Striping</h2>
//       {/* <h3>and Pavement Marking</h3> */}


//       {/* <h3>A line painting company</h3> */}
//     </div>
// </div>
// </div>