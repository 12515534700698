
import Carousel from 'react-bootstrap/Carousel';
import country_road from '../images/country_road.jpg';
import clean_road from '../images/clean_road.jpeg'
import machine_striping from '../images/machine_striping.jpeg'

function Home() {
  return (<div>
    <div className="statement p-4">
    Enhancing the safety and appearance of your property, our local striping and pavement marking services offer a cost-effective 
    solution to maintain well-defined lanes, parking spaces, and accessibility features. With meticulous attention to detail, we 
    ensure that each line is painted with precision, contributing to a smoother traffic flow and a positive first impression for 
    your customers. Investing in our services not only elevates the functionality of your space but also demonstrates your 
    commitment to safety and professionalism.
    </div>

<div className="BootCar p-4">
<Carousel fade="true" className="p-4">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={ country_road }
          alt="First slide"
        />
        <Carousel.Caption>
          <div className="Home_Text">
          <h5>BUSINESS PRESENCE</h5>
          <p>A well-maintained parking lot with fresh stripes gives a positive first impression to visitors and customers and reflects 
            a sense of professionalism and care.</p>
        </div></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="Home_Text">
        <img
          className="d-block w-100"
          src={ clean_road }
          alt="Second slide"
        />
        </div><Carousel.Caption>
          <h5>A SAFETY FOCUS</h5>
          <p>Clear, visible lines help guide drivers and pedestrians, reducing the risk of accidents, injuries and minimizes
            confusion and potential collisions</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <div className="Home_Text">
        <img
          className="d-block w-100"
          src={ machine_striping }
          alt="Third slide"
        />
        </div><Carousel.Caption>
        <div className="Home_Text">
          <h5>PROPERTY VALUE</h5>
          <p>
          Regular maintenance, including restriping, can increase the value of your property. It shows that the property 
          is well-cared for, which can be appealing to potential buyers or tenants</p>
        </div></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>

  </div>);
}

export default Home;