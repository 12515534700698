import '../styles/app.css';

import { Accordion } from 'react-bootstrap';
import { Fire, PersonWheelchair, Bicycle, ArrowLeftRight, SignStopLights, CarFront } from 'react-bootstrap-icons'

function Services() {
  return (<>
 {/* defaultActiveKey="0" */}
    <Accordion className="p-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header><CarFront size={30}/>&nbsp;General Parking Spaces</Accordion.Header>
        <Accordion.Body>
        Parking space painting is essential for maintaining order and safety in parking areas. Clearly marked parking spaces help maximize 
        the use of available space, ensuring that vehicles are parked efficiently and reducing the likelihood of accidents or congestion.
         These markings also provide guidance for drivers, making it easier to navigate and park correctly, which is particularly important
          in busy or crowded areas. Additionally, designated spaces for disabled parking, loading zones, and other specific needs are 
          clearly identified through painting, promoting accessibility and compliance with regulations. Overall, well-maintained parking 
          space markings contribute to a smoother, safer, and more organized parking experience for everyone.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><PersonWheelchair size={30}/>&nbsp;Accessible Parking Spaces</Accordion.Header>
        <Accordion.Body>
        When businesses provide parking lots or garages, it’s essential to include accessible parking spaces that comply with the Americans with Disabilities Act (ADA). These spaces differ from traditional parking spots and are designed to make it easier for people with disabilities to access goods and services. Here are some key features of proper ADA parking spots:
<ol>
  <br />
<li><b>Location:</b> Accessible parking spaces should be located on the shortest accessible route to the entrance. An accessible route ensures that people with disabilities can enter and move through the facility easily.</li>
<li><b>Access Aisles:</b> Accessible parking spaces must have access aisles next to them. These aisles provide designated areas for wheelchair users to get in and out of their vehicles. Access aisles should be marked, level with the parking space, and at least 60 inches wide.</li>
<li><b>Parking Space Requirements:</b></li>
<ul>
<li><u>Car Accessible Spaces</u>: These spaces should be at least 96 inches wide, have a firm and slip-resistant surface, and display the international symbol of accessibility.</li>
<li><u>Van Accessible Spaces (choose one option)</u>:</li>
<ol>
<li>At least 132 inches wide, with a 60-inch-wide access aisle, proper slope, and 98 inches of vertical clearance.</li>
<li>At least 96 inches wide, with a 96-inch-wide access aisle, proper slope, and the same vertical clearance.</li>
</ol>

</ul></ol>
Ensuring proper <a href="https://www.ada.gov/topics/parking/" target='_blank'>ADA</a> parking spots benefits everyone by promoting inclusivity and equal access.
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><Fire size={30}/>&nbsp;Fire Designated Areas </Accordion.Header>
        <Accordion.Body>
        Fire lanes are designated areas in parking lots that allow quick access for emergency vehicles during crises such as fires, medical incidents, or criminal offenses. Proper fire lane striping is crucial for safety and legal compliance. Here’s what you need to know:
<ol>
  <br />
<li><b>Purpose of Fire Lanes: </b>
Fire lanes provide quick access for firefighters, emergency responders, and vehicles.
They ensure unobstructed paths to buildings during emergencies.</li>
<li><b>Importance of Clear Fire Lane Striping:</b>
  <ul> 
  <li><u>Clear Communication:</u> Bright, well-marked fire lanes prevent confusion for customers, indicating where parking is prohibited.</li>
  <li><u>Quick Response:</u> Properly marked fire lanes reduce emergency response time.</li>
  <li><u>Legal Adherence:</u> Fire lane maintenance is a legal requirement in most states.</li>
  <li><u>Regulations & Requirements:</u> Fire lanes can be identified by red painted curbs, a six-inch-wide red stripe along the lane, or approved signs.
  Fire lane signs should be visible, clear of obstructions, and fitted to stationary objects. They must have reflective, two-inch-tall red letters on a white background.
  </li>
  </ul>
</li></ol>
Remember, maintaining fire lane striping can make a life-saving difference during emergencies!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header><Bicycle size={30}/>&nbsp;Bicycle Designated Areas</Accordion.Header>
        <Accordion.Body>
        <div style={{ display: "flex", flexDirection: "column" }}><div style={{ display: "flex"}}>Bicycle lane marking and striping play a crucial role in ensuring the safety and efficiency of urban cycling. These markings 
        clearly delineate spaces designated for cyclists, reducing the risk of accidents by separating them from motor vehicle 
        traffic. Typically, bicycle lanes are marked with solid white lines and bicycle symbols, making them easily recognizable. 
        In some areas, green paint is used to highlight conflict zones, such as intersections, to alert both cyclists and drivers. 
        Properly marked bicycle lanes not only promote safer cycling but also encourage more people to use bicycles as a mode of 
        transportation, contributing to healthier and more sustainable urban environments.</div> <div style={{ display: "flex"}}></div></div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header><SignStopLights size={30}/>&nbsp;Parking Stoppers & Light Bases</Accordion.Header>
        <Accordion.Body>
        Painting parking stoppers is crucial for enhancing visibility and safety in parking areas. Clearly painted stoppers help drivers
         see the boundaries of parking spaces, reducing the risk of vehicles overshooting and potentially causing damage or accidents. 
         This is especially important in low-light conditions or for new drivers who might not be as familiar with parking lot layouts.
          Additionally, painted stoppers can indicate specific parking regulations, such as reserved spots or no-parking zones, ensuring 
          compliance and order. Overall, well-maintained and visible parking stoppers contribute to a safer and more organized parking
           environment.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header><ArrowLeftRight size={30}/>&nbsp;Stanciling</Accordion.Header>
        <Accordion.Body>
        Road and parking stenciling is vital for maintaining safety, organization, and efficiency in traffic and parking management. 
        These stencils provide clear, visual cues for drivers and pedestrians, indicating lanes, directions, and specific parking
         regulations. For instance, stenciled arrows guide traffic flow, while symbols and text designate handicapped parking, loading
          zones, or no-parking areas. This clarity helps prevent confusion and reduces the risk of accidents. Additionally, stenciling 
          enhances the overall appearance of roads and parking lots, contributing to a well-maintained and professional environment. By 
          ensuring that everyone understands and follows the rules, stenciling plays a key role in creating safer and more orderly public
           spaces.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </>);
}

export default Services;


{/* <h3><PaintBucket size={45} /> Services</h3>
<div className="ServicesListContainer"> 

  <div>ADA Handicap Spaces</div>
  <div>Stanciling</div>
  <div>Parking Stoppers</div>
  <div>General Parking Spaces</div>
  <div>Double Parking Spaces</div>
  <div>Light Pole Bases</div>
  <div>Crosswalk Striping</div>
  <div>Fire Lane Marking</div>
  <div>Bike Lane Marking</div>
  <div></div>

</div> */}