import '../styles/statement.css';
import { Card } from 'react-bootstrap';
import mands from '../images/matthewstephanie.jpg'

function AboutUs() {
  return (
    <div className="missionstatement p-4">
<br />
<Card style={{ maxWidth: '40rem', boxShadow: "3px 3px darkgray", background: "white", color: "black"}} className="p-4">
      <Card.Img variant="" src={ mands } />
      <Card.Body>
        <Card.Title>The Plumbline Striping Crew</Card.Title>
        <Card.Text>
          Yep, there are only two of us! We are a small veteren owned business located right here in Baraboo Wisconsin.
        </Card.Text>

      </Card.Body>
    </Card>
    </div>
  );
}

export default AboutUs;