import './styles/app.css';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import TopBar from './components/TopBar';
import Home from './components/Home';
import MissionStatement from './components/MissionStatement';
import Services from './components/Services'
import AboutUs from './components/AboutUs';




function App() {

  const [ home, setHome ] = useState(true);
  const [ mission, setMission ] = useState(false);
  const [ services, setServices ] = useState(false);
  const [ about, setAbout ] = useState(false);

  

  return (
    <div className="App">
      <Helmet>
        <title>Plumbline Striping</title>
        <meta name="description" content="Road and parking lot striping service" />
        <meta name="keywords" content="
          paint striping,
          road striping,
          parking lot marking,
          traffic lane painting,
          roadway marking,
          line painting services,
          pavement striping,
          line painting,
          cement painting,
          line striping,
          parking stripes,
          parking lines,
          fire lane painting,
          ada painting,
          ada line painting,
          lightpole painting,
          yellow line,
          white line,
          green line,
          bike lane painting,
          EV painting,
          asphalt painting,
          asphault line painting,
          pavement marking
          " />
        <meta name="author" content="Matthew" />
        <meta property="og:title" content="Plumbline Striping" />
        <meta property="og:url" content="https://plumblinestriping" />
      </Helmet>
      
      
      <TopBar 
        home={ home }
        setHome={ setHome }
        mission={ mission }
        setMission={ setMission }
        services={ services }
        setServices={ setServices }
        about={ about }
        setAbout={ setAbout }
      />
      { home ? <div className="Home"><Home /></div>:<></>}
      { services ? <Services />:<></>}
      { mission ? <MissionStatement />:<></>}
      { about ? <AboutUs />:<></>}

      
    </div>
  );
}

export default App;
