import '../styles/missionstatement.css';
import { Card } from 'react-bootstrap';
import mission_wood from '../images/mission_wood.jpg';

function MissionStatement() {
  return (
    <div className="missionstatement p-4">
<br />
<Card style={{ maxWidth: '40rem', boxShadow: "3px 3px darkgray", background: "white", color: "black"}} className="p-4">
      <Card.Img variant="" src={ mission_wood } />
      <Card.Body>
        <Card.Title>OUR MISSION</Card.Title>
        <Card.Text>
        At Plumbline Striping, our mission is to provide top-quality 
        striping and pavement marking services to the local community while maintaining a 
        commitment to honesty, integrity, and exceptional value. As a 
        veteran-owned business, we're dedicated to supporting our fellow 
        neighbors and contributing to the growth and prosperity of
        Wisconsin and our local community. With a focus on precision, professionalism, and 
        personalized attention, we aim to exceed customer expectations 
        and earn your trust as your go-to provider for all striping and pavement marking 
        needs.
        </Card.Text>

      </Card.Body>
    </Card>

    </div>
  );
}

export default MissionStatement;